<template>
    <el-time-picker
        :is-range="item.range"
        v-model="value"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="HH:mm:ss"
        :unlink-panels="true"
    ></el-time-picker>
</template>

<script>
import { RESET_SEARCH } from "../../views/index/events/eventBus";
export default {
    data() {
        return {
            value: ""
        };
    },
    props: {
        data: { type: Object, default: null },
        item: { type: Object, default: null }
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.$set(this.data, this.item.key, this.value);
                if (this.item.range) {
                    if (this.value) {
                        this.$set(
                            this.data,
                            this.item.keys[0],
                            this.parseTimeStr(this.value[0])
                        );
                        this.$set(
                            this.data,
                            this.item.keys[1],
                            this.parseTimeStr(this.value[1])
                        );
                    } else {
                        this.$delete(this.data, this.item.keys[0]);
                        this.$delete(this.data, this.item.keys[1]);
                    }
                }
            }
        }
    },
    mounted() {
        this.$EventBus.$on(RESET_SEARCH, this.reset);
        if (this.item.range) {
            this.value = [
                this.parseSecond(this.data[this.item.keys[0]]) || "",
                this.parseSecond(this.data[this.item.keys[1]]) || ""
            ];
        } else {
            this.value = this.data[this.item.key];
        }
    },
    methods: {
        reset() {
            this.value = "";
        },
        parseSecond(sec) {
            sec = Math.floor(sec / 1000);
            let H = Math.floor(sec / 3600);
            let M = Math.floor((sec % 3600) / 60);
            let S = Math.floor((sec % 3600) % 60);
            H < 10 && (H = "0" + H);
            M < 10 && (M = "0" + M);
            S < 10 && (S = "0" + S);
            if (H > 24) {
                H = 23;
                M = 59;
                S = 59;
            }
            return H + ":" + M + ":" + S;
        },
        parseTimeStr(str) {
            let array = str.split(":");
            if (array.length > 1) {
                return (
                    (parseInt(array[0]) * 3600 +
                        parseInt(array[1]) * 60 +
                        parseInt(array[2])) *
                    1000
                );
            } else {
                return str;
            }
        }
    }
};
</script>

<style lang="scss">
.el-date-editor--timerange.el-input__inner {
    width: 100%;
}
</style>